<template>
  <base-material-card
    color="primary"
    icon="mdi-pencil"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Informações Básicas</div>
    </template>
    <v-form class="pt-5">
      <v-row
        align="center"
        no-gutters
        class="ml-0"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Nome do Layer"
          >
            <v-text-field
              label="Nome do Layer"
              outlined
              :error-messages="errors"
              v-model="nomeRelatorioLocal"
              class="ml-4"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Base de dados"
          >
            <v-select
              no-gutters
              outlined
              :items="bases"
              :loading="loading"
              item-value="nome_tabela"
              item-text="descricao"
              label="Base de Dados Geográfica"
              v-model="baseDadosLocal"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';

export default {
  props: {
    baseDados: {
      type: String,
      required: true
    },
    nomeRelatorio: {
      type: String,
      required: true
    }
  },

  data: () => ({
    bases: [],
    loading: false
  }),

  created() {
    this.getBasesDados();
  },

  computed: {
    baseDadosLocal: {
      get() {
        return this.baseDados;
      },
      set(newValue) {
        this.$emit('update:baseDados', newValue);
        const base = this.bases.find((base) => base.nome_tabela === newValue);
        this.$emit('update:tipoDadoGeografico', base.tipo_dado_geografico);
      }
    },

    nomeRelatorioLocal: {
      get() {
        return this.nomeRelatorio;
      },
      set(newValue) {
        this.$emit('update:nomeRelatorio', newValue);
      }
    }
  },

  methods: {
    getBasesDados() {
      this.loading = true;
      BasesDadosService.getBasesDadosGeograficasLiberadas()
        .then((response) => (this.bases = response.data))
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar bases de dados para layers.',
            '',
            { position: 'topRight' }
          );
        })
        .finally((this.loading = false));
    }
  }
};
</script>
